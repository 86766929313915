.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  text-align: center;
  padding: 0 20px;
}

.container > * {
  animation: fade-in 1s ease;
}

.paused {
  animation-play-state: paused;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  } /* adjust as needed */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title {
  font-size: 2.5em;
}

.details {
  list-style-type: none; /* Remove bullet points */
  padding-left: 0; /* Remove padding */
}

.details li {
  margin-bottom: 1em; /* Add space between lines */
}

.details li:hover > div{
  transform: scale(1.5);
}

.details li:hover{
  font-weight: bold;
  transform: scale(1.4);
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1em;
  vertical-align: middle;
}

.link {
  color: white;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
  .title {
    font-size: 1.5em;
  }

  .details {
    font-size: 1em;
  }
}
.snail {
  position: absolute;
  bottom: -10%;
  left: -30%;
  animation: move-snail 50s linear infinite;
  z-index: -1;
}

@media (max-width: 600px) {
  .snail {
    height: 20%;
    transform: scale(0.7);
  }
}

@keyframes move-snail {
  from {
    left: -30%;
  }
  to {
    left: 100%;
  }
}
