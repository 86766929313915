.footer {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  bottom: 0;
}

.social {
  display: flex;
}

.fun:hover {
  color: gold;
  transform: scale(1.1);
}

.icon {
  margin-right: 15px;
  color: #ffffff;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.1);
  font-weight: bold;
}

.info p {
  font-weight: bold;
  font-size: 14px;
}

@keyframes move {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

/* For tablets */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .footer {
    padding: 10px;
  }
}
