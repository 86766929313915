/* global.css */

@font-face {
  font-family: "Monocraft";
  src: url("../assets/fonts/Monocraft.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Monocraft", sans-serif;
  font-size: 16px;
  background-color: #148021; /* vibrant green color  #1abc9c */
  color: #ffffff; /* White color for text */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;
}

/* Additional styles to ensure content fits within the screen */
.container {
  max-width: 1200px; /* Adjust maximum width as needed */
  margin: 0 auto; /* Center content horizontally */
}
