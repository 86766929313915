.header {
  padding: 3vh;
  padding-top: 4vh;
  display: flex;
  height: 10vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title:hover {
  transform: rotate(360deg);
  transition: transform 1s ease-in-out;
}

.link{
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.title {
  color: #ffffff;
}

.joke {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100px;
  align-items: center;
  flex-direction: column;
}

.jokeIcon {
  margin-right: 10px;
  font-size: 20px;
  color: #ffcc00; /* Choose a cool color for the icon */
}

.jokeSetup {
  font-weight: bold;
  font-size: 15px;
}

.jokePunchline {
  font-style: italic;
  font-weight: bold;
  font-size: 13px;
}

.burgermenu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.burgermenu div {
  width: 2rem;
  height: 0.25rem;
  background-color: #ffffff;
}

.menu ul {
  display: flex;
  text-decoration: none;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  color: white; /* Make the text color white */
}

.menu ul li a {
  margin: 0 10px; /* Add horizontal space between li elements */
  color: white; /* Make the text color white */
  text-decoration: none;
}

.menu li:hover {
  /* text-decoration: underline; */
  font-weight: bold;
  font-size: large;
}

/* For tablets */
@media (max-width: 768px) {
  .menu ul {
    display: flex;
    flex-direction: column;
  }
  .burgermenu {
    display: flex;
    align-self: flex-end;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .header {
    padding: 10px;
  }

  .jokeContainer {
    display: none;
  }
  .title {
    font-size: 18px;
  }
  .menu ul {
    display: flex;
    flex-direction: column;
  }
  .burgermenu {
    display: flex;
    align-self: flex-end;
  }
}
